<template>
  <v-main>
    <div class="text-center">
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">password</p>
        <v-text-field v-model="password_plain_text" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">name</p>
        <v-text-field v-model="name" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">nickname</p>
        <v-text-field v-model="nickname" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">zip</p>
        <v-text-field v-model="zip" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">gender</p>
        <v-text-field v-model="prefecture" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">birthday</p>
        <v-text-field v-model="birthday" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">gender</p>
        <v-text-field v-model="gender" single-line outlined dense></v-text-field>
      </v-col>
      <v-btn rounded class="maincolor-bg default_button"
             @click.stop="callApi()">簡易会員編集</v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      init: this.storageGet('*'),
      name:null,
      nickname:null,
      password_plain_text:null,
      zip:null,
      birthday:null,
      gender:null,
      prefecture:null,
    };
  },
  mounted () {
  },
  methods: {
    async callApi() {
      try {

        // アプリ会員情報更新API
        let user_req = {
          'type': 'EC',
          'membership_status': 2,
        };

        if (this.password_plain_text) {
          user_req.password_plain_text = this.password_plain_text
        }
        if (this.name) {
          user_req.name = this.name
        }
        if (this.nickname) {
          user_req.nickname = this.nickname
        }
        if (this.zip) {
          user_req.zip = this.zip
        }
        if (this.prefecture) {
          user_req.prefecture = this.prefecture
        }
        if (this.birthday) {
          user_req.birthday = this.birthday
        }
        if (this.gender) {
          user_req.gender = this.gender
        }
        if (this.membership_status) {
          user_req.membership_status = this.membership_status
        }
        await this.callPfApiFromEcMock('/user/update/' + this.init.device_uid, user_req);

        // サブメニュー表示
        this.requestToNativeToShowSubmenu()

      } catch(e) {
        console.log(e);
        this.loading = false
        this.callDialog('エラー', e.message, 3);
      }
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}

</style>
